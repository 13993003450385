/* You can add global styles to this file, and also import other style files */
//@tailwind base;
//@tailwind components;
//@tailwind utilities;

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@layer base {
  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
    @apply w-full rounded-md border border-gray-300 px-3 py-3 text-xs placeholder-gray-400 focus:z-10  focus:border-primary-500 focus:outline-none focus:ring-primary-500 dark:border-night-500 dark:bg-night-700 dark:text-night-400 dark:placeholder-night-200;
  }

  [type='checkbox'],
  [type='radio'] {
    @apply h-4 w-4 rounded border-gray-300 bg-gray-100 text-green-600 focus:ring-2 focus:ring-green-500 dark:border-night-600 dark:bg-night-700 dark:ring-offset-night-800 dark:focus:ring-green-600;
  }
}

@layer components {
  .dropdown-content {
    @apply pointer-events-none scale-95 opacity-0 duration-100 ease-in;
  }

  .dropdown:hover > .dropdown-content {
    @apply pointer-events-auto block scale-100 animate-fade-in-up opacity-100 duration-200;
  }

  .card {
    @apply bg-white border-t-[1px] border-b-[1px] p-6 sm:m-2 sm:rounded-lg sm:shadow-md sm:border-[1px];
  }

  .card-detail-product {
    @apply bg-white p-6 sm:m-2 rounded-lg shadow-md border-[1px];
  }

  .card-header {
    @apply text-2xl text-primary-900 mb-3;
  }

  .modal-card {
    @apply bg-white rounded-lg border-2 p-4 flex flex-col justify-between content-between shadow-2xl ;

    .modal-card-header {
      @apply text-2xl text-primary-800 mb-4;
    }

    .modal-card-body {
    }

    .modal-card-footer {
      @apply flex flex-row items-center justify-between pt-4;
    }
  }

  .kn-back-color-gray {
    background-color: rgb(248 250 252);
  }

  .kn-back-color-white {
    background-color: white;
  }

  .kn-column-border {
    //border-color: rgb(248 250 252);
    //border-style: solid;
    //border-width: 0 1px 0 1px;
  }

  :host ::ng-deep a {
    color: theme('colors.primary.900');
  }

  .k-table {
    @apply w-full mt-4 text-gray-800;

    caption {
      @apply text-left text-2xl text-primary-900;
    }

    th {
      @apply border-b-[1px] border-gray-200 text-gray-600;
    }

    tr {
      @apply border-b-[1px] border-gray-200;
    }

    td {
      @apply py-1;
    }

  }
}
